(function ($) {
  $.fn.selectpicker.defaults = {
    noneSelectedText: 'Inget valt',
    noneResultsText: 'Inget sökresultat matchar {0}',
    countSelectedText: function (numSelected, numTotal) {
      return (numSelected === 1) ? '{0} alternativ valt' : '{0} alternativ valda';
    },
    maxOptionsText: function (numAll, numGroup) {
      return [
        'Gräns uppnåd (max {n} alternativ)',
        'Gräns uppnåd (max {n} gruppalternativ)'
      ];
    },
    selectAllText: 'Markera alla',
    deselectAllText: 'Avmarkera alla',
    multipleSeparator: ', '
  };
})(jQuery);
